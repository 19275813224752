import React, { useState } from 'react';

function ContactForm() {
    const [formData, setFormData] = useState({
        'entry.407232558': '',
        // email: '',
        // message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        // console.log(formData);

        // Construct form data for submission
        const formPayload = new FormData();
        for (const key in formData) {
            formPayload.append(key, formData[key]);
        }

        // Submit the form data to Google Forms
        fetch("https://docs.google.com/forms/d/e/1FAIpQLScmLtncibaz5kAlZ8ggbhh7218bgWKamkgmtYt5RwhWlx6aHw/formResponse", {
            method: 'POST',
            body: formPayload,
            mode: 'no-cors',  // Google Forms doesn't support CORS, so this is necessary
        }).then(() => {
            // Handle successful submission here (e.g., display a thank you message)
            console.log('Form Submitted Successfully');
            console.log('name:', formData['entry.407232558']);
        }).catch(e => {
            // Handle errors here
            console.log('Error submitting form', e);
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
            type='text'
            name='entry.407232558'
            placeholder='YourName'
            value={formData['entry.407232558']}
            onChange={handleChange}
            />
            <button type='submit'>Submit</button>
        </form>
    );
}

export default ContactForm;